import React, { useState } from "react";
import { FaBars, FaSignOutAlt } from "react-icons/fa";
import { Outlet } from "react-router-dom";
import { Sidebar } from "../Components/Sidebar";
import { useAuth } from "../hooks/useAuth";

const Dashboard = () => {
  const { logout } = useAuth();
  const [sideBaropen, setSideBaropen] = useState(false);

  return (
    <div className="bg-blue-50 md:h-screen">
      <div className="bg-white p-5 flex justify-between">
        <div className="flex items-center gap-4">
          <FaBars
            className="cursor-pointer block md:hidden"
            onClick={() => setSideBaropen(!sideBaropen)}
          />
          <h2 className="font-bold">Dashboard</h2>
        </div>
        <div>
          <span
            className=" cursor-pointer  hover:text-red-500"
            onClick={logout}
          >
            {" "}
            <FaSignOutAlt />
          </span>
        </div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap">
        <div className="w-full md:w-1/5 ">
          <Sidebar sideBaropen={sideBaropen} setSideBaropen={setSideBaropen} />
        </div>
        <div className="w-full  md:w-4/5 p-5 md:p-8 bg-white m-2 md:m-12 shadow-lg rounded-md">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
