import { useQuery } from "@tanstack/react-query";
import React from "react";
import { notify } from "../../Components/Utility/notify";
import { Axios } from "../../services/axiosInstance";

const Deposit = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    const email = e.target.email.value;
    const amount = e.target.Amount.value;

    const res = await Axios.post("/add-balance", {
      amount,
      email,
    });
    if (res.data.acknowledged) {
      notify("Balance Added Successfully !!  ");
      refetch();
      e.target.reset();
    }
  };

  const { data, refetch, isLoading } = useQuery(["balances"], () =>
    Axios.get("/balances")
  );

  console.log(data);
  if (isLoading) {
    return "Loading....";
  }

  return (
    <div>
      <h2 className="text-2xl mb-12 text-gray-700 font-bold">
        Add Balance To Agent Account Account
      </h2>
      <div className="w-full max-w-md">
        <form onSubmit={handleSubmit} className="bg-white   mb-4">
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Agent Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="email"
              name="email"
              id="email"
              placeholder="user@domain.com"
              required
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="Balance"
            >
              Amount
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              type="number"
              name="Amount"
              id="Amount"
              placeholder="10000"
              required
            />
          </div>

          <div className="flex items-center justify-between">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="submit"
            >
              Add Balance
            </button>
          </div>
        </form>
      </div>
      <div className="p-3">
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
              <tr>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Amount</div>
                </th>
                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">Email</div>
                </th>

                <th className="p-2 whitespace-nowrap">
                  <div className="font-semibold text-left">createdAt</div>
                </th>
              </tr>
            </thead>
            <tbody className="text-sm divide-y divide-gray-100">
              {isLoading
                ? "loading...."
                : data?.data?.balances?.map((info) => (
                    <tr key={info._id}>
                      <td className="p-2 whitespace-nowrap">
                        <div className="flex items-center">
                          <div className="font-medium text-gray-800">
                            {info.amount}
                          </div>
                        </div>
                      </td>
                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left flex items-center gap-1 font-semibold">
                          {info.email}
                        </div>
                      </td>

                      <td className="p-2 whitespace-nowrap">
                        <div className="text-left font-medium ">
                          {info?.createdAt.split("T")[0]}
                        </div>
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Deposit;
