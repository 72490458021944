import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Axios } from "../../services/axiosInstance";

const Overview = () => {
  const { data, isLoading } = useQuery(["admin"], async () =>
    Axios.get(`/card-info`)
  );
  if (isLoading) {
    return "Loading....";
  }

  return (
    <div className="flex items-center  ">
      <div>
        <div className="grid gap-7 grid-cols-2 lg:grid-cols-4  ">
          <div className="p-5 bg-gray-50 border rounded ">
            <div className="text-base text-gray-400 ">
              Total Opening Balance
            </div>
            <div className="flex items-center pt-1">
              <div className="text-2xl font-bold  text-gray-900 ">
                {data?.data?.totalOpeningBalance}
              </div>
            </div>
          </div>
          <div className="p-5 bg-gray-50 border rounded ">
            <div className="text-base text-gray-400 ">Current Balance</div>
            <div className="flex items-center pt-1">
              <div className="text-2xl font-bold  text-gray-900 ">
                {data?.data?.totalWithdraw +
                  (data?.data?.totalOpeningBalance - data?.data?.totalDeposit)}
              </div>
            </div>
          </div>
          <div className="p-5 bg-gray-50 border rounded ">
            <div className="text-base text-gray-400 ">Total Deposit</div>
            <div className="flex items-center pt-1">
              <div className="text-2xl font-bold  text-gray-900 ">
                {data?.data?.totalDeposit}
              </div>
            </div>
          </div>
          <div className="p-5 bg-gray-50 border rounded ">
            <div className="text-base text-gray-400 ">Total Withdraw</div>
            <div className="flex items-center pt-1">
              <div className="text-2xl font-bold  text-gray-900 ">
                {data?.data?.totalWithdraw}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
