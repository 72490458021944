import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import { Axios } from "../../services/axiosInstance";

const ViewUser = () => {
  //get params from react router
  const { email } = useParams();
  const { data, isLoading } = useQuery(["profile", email], async () =>
    Axios.get(`/view/${email}`)
  );
  if (isLoading) {
    return "Loading....";
  }

  return (
    <div>
      <div className="text-gray-700 mb-8">
        <h2 className="text-3xl font-bold"> {data?.data?.user.name}</h2>
        <p>Email: {data?.data?.user.email}</p>
        <p>Phone: {data?.data?.user.phone}</p>
      </div>
      <section className="antialiased   text-gray-600 h-full ">
        <div>
          <div className="grid gap-7 grid-cols-2 lg:grid-cols-3  ">
            <div className="p-5 bg-gray-50 border rounded ">
              <div className="text-base text-gray-400 ">Current Balance</div>
              <div className="flex items-center pt-1">
                <div className="text-2xl font-bold  text-gray-900 ">
                  {data?.data?.totalBalance}
                </div>
              </div>
            </div>
            <div className="p-5 bg-gray-50 border rounded ">
              <div className="text-base text-gray-400 ">Total Deposit</div>
              <div className="flex items-center pt-1">
                <div className="text-2xl font-bold  text-gray-900 ">
                  {data?.data?.totalDeposit}
                </div>
              </div>
            </div>
            <div className="p-5 bg-gray-50 border rounded ">
              <div className="text-base text-gray-400 ">Total Withdraw</div>
              <div className="flex items-center pt-1">
                <div className="text-2xl font-bold  text-gray-900 ">
                  {data?.data?.totalWithdraw}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full mx-auto  ">
          <header className="px-5 py-4 border-b border-gray-100">
            <h2 className="font-semibold text-gray-800">Latest Transitions </h2>
          </header>
          <div className="flex flex-wrap">
            <div className="p-3 w-full md:w-1/2">
              <header className="p-2 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800">Withdraw</h2>
              </header>
              <div className="overflow-x-auto">
                <table className="table-auto w-full">
                  <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                    <tr>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Amount</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Player Email
                        </div>
                      </th>

                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">createdAt</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-100">
                    {isLoading
                      ? "loading...."
                      : data?.data?.allWithdraw?.map((info) => (
                          <tr key={info._id}>
                            <td className="p-2 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="font-medium text-gray-800">
                                  {info.amount}
                                </div>
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left flex items-center gap-1 font-semibold">
                                {info.email}
                              </div>
                            </td>

                            <td className="p-2 whitespace-nowrap">
                              <div>
                                {" "}
                                <span
                                  className={`text-xs   rounded p-1 text-white ${
                                    info.status === "approved"
                                      ? "bg-green-400"
                                      : "bg-red-400"
                                  }`}
                                >
                                  {" "}
                                  {info.status}
                                </span>
                              </div>
                              <div className=" font-medium ">
                                {info.createdAt?.split("T")[0]}
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="p-3 w-full md:w-1/2">
              <header className="p-2 border-b border-gray-100">
                <h2 className="font-semibold text-gray-800">Deposit</h2>
              </header>
              <div className="overflow-x-auto">
                <table className="table-auto w-full">
                  <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                    <tr>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">Amount</div>
                      </th>
                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">
                          Player Email
                        </div>
                      </th>

                      <th className="p-2 whitespace-nowrap">
                        <div className="font-semibold text-left">createdAt</div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-sm divide-y divide-gray-100">
                    {isLoading
                      ? "loading...."
                      : data?.data?.allDeposits?.map((info) => (
                          <tr key={info._id}>
                            <td className="p-2 whitespace-nowrap">
                              <div className="flex items-center">
                                <div className="font-medium text-gray-800">
                                  {info.amount}
                                </div>
                              </div>
                            </td>
                            <td className="p-2 whitespace-nowrap">
                              <div className="text-left flex items-center gap-1 font-semibold">
                                {info.email}
                              </div>
                            </td>

                            <td className="p-2 whitespace-nowrap text-center">
                              <div>
                                {" "}
                                <span
                                  className={`text-xs   rounded p-1 text-white ${
                                    info.status === "approved"
                                      ? "bg-green-400"
                                      : "bg-red-400"
                                  }`}
                                >
                                  {" "}
                                  {info.status}
                                </span>
                              </div>
                              <div className=" font-medium ">
                                {info.createdAt?.split("T")[0]}
                              </div>
                            </td>
                          </tr>
                        ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ViewUser;
